<template>
  <div>
    <a-card>
      <attack-maps class="mb-4"/>
      <a-form>
        <a-form-item name="area" :label="$t('Domain')" :wrapper-col="{ span: 6}">
          <a-select v-model:value="host" :options="hosts" />
        </a-form-item> 
      </a-form>
      <a-table :columns="columns" 
               :data-source="data"
               :pagination="pagination"
               :loading="loading"
               @change="handleTableChange">
         <span slot="shadow_domains" slot-scope="shadow_domains">
          <a-tag
            v-for="shadow in shadow_domains"
            :key="shadow"
          >
            {{ shadow }}
          </a-tag>
        </span>
        <a-table slot="expandedRowRender"
                 slot-scope="record"
                 :columns="innerColumns" 
                 :data-source="record.decisions" 
                 :pagination="false"> 
        </a-table>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import axios from "@/plugins/axios.js"
import AttackMaps from './Maps.vue';
import {EventBus, Events} from "@/plugins/event-bus.js";


export default {
  name: 'Attack',
  components: {
    AttackMaps
  },
  data() {
    return {
      data: null,
      host: null,
      hosts: [],
      loading: false,
      pagination: {
        pageSize: 20,
        total: 0
      }
    }
  },
  watch: {
    host() {
      this.fetchDomainAttack();
    }
  },
  computed: {
    columns() {
      return [{
        title: this.$t('Domain'),
        dataIndex: 'main_domain',
      }, 
        {
        title: this.$t('ShadowDomain'),
        dataIndex: 'shadow_domains',
        scopedSlots: { customRender: 'shadow_domains' }
      },{
        title: this.$t('TotalAttack'),
        dataIndex: 'total_attack'
      }]
    },
    innerColumns() {
      return [{
        title: this.$t('Date'),
        dataIndex: 'created_at',
      }, {
        title: this.$t('AttackType'),
        dataIndex: 'scenario',
      }, {
        title: this.$t('SourceIP'),
        dataIndex: 'value',
      },
      {
        title: this.$t('SourceCountry'),
        dataIndex: 'alert_decisions.source_country'
      },
      {
        title: this.$t('SourceISP'),
        dataIndex: 'alert_decisions.source_as_name'
      },
      {
        title: this.$t('AttackCount'),
        dataIndex: 'alert_decisions.events_count'
      },
      {
        title: this.$t('Action'),
        dataIndex: 'type',
      }];
    }
  },
  created() {
    this.fetchDomainAttack();
    this.fetchDomainList();
  },
  beforeDestroy() {
    EventBus.$off(Events.DOWNLOAD_CERTIFICATE);
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.fetchDomainAttack(pagination.current);
    },
    fetchDomainList() {
      axios.get('attack/all-domain/').then(response => {
        this.hosts = [{value: null, label: this.$t('All')}];
        response.data.forEach(item => {
          this.hosts.push({value: item, label: item});
        });
      }).catch((error) => {
        console.log(error);
      });
    },
    fetchDomainAttack(page=1) {
      this.loading = true;
      axios.get('attack/domain/', {params: {perpage: this.pagination.pageSize, page: page, host: this.host}}).then(response => {
        this.data = response.data.results 
        this.pagination.total = response.data.count
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>
