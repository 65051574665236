<template>
  <div style="width: 70%; margin-left: auto; margin-right: auto;">
    <a-form>
      <a-form-item :label="$t('FocusOn')" 
                   :wrapper-col="{ span: 6}">
        <a-select v-model:value="region" 
                  :options="regions" />
      </a-form-item>
    </a-form>
    <GChart :settings="{ packages: ['geochart'] }"
            type="GeoChart"
            :data="chartData"
            :options="chartOptions"
            :resizeDebounce="500"/>
  </div>
</template>

<script>
import axios from "@/plugins/axios.js"
import { GChart } from 'vue-google-charts/legacy'

export default {
  name: 'AttackMaps',
  components: {
    GChart,
  },
  data() {
    return {
      chartData: [['Country', 'Attack']],
      region: 'world',
      regions: [
        {
          'value': 'world', 
          'label': 'World'
        },
        {
          'value': '015',
          'label': 'Northern Africa'
        },
        {
          'value': '011',
          'label': 'Western Africa',
        },
        {
          'value': '017',
          'label': 'Middle Africa'
        },
        {
          'value': '014',
          'label': 'Eastern Africa'
        },
        {
          'value': '018',
          'label': 'Southern Africa',
        },
        {
          'value': '154',
          'label': 'Northern Europe'
        },
        {
          'value': '155',
          'label': 'Western Europe'
        },
        {
          'value': '151',
          'label': 'Eastern Europe'
        },
        {
          'value': '039',
          'label': 'Southern Europe'
        },
        {
          'value': '021',
          'label': 'Northern America'
        },
        {
          'value': '029',
          'label': 'Caribbean'
        },
        {
          'value': '013',
          'label': 'Central America'
        },
        {
          'value': '005',
          'label': 'South America'
        },
        {
          'value': '143',
          'label': 'Central Asia'
        },
        {
          'value': '030',
          'label': 'Eastern Asia'
        },
        {
          'value': '034',
          'label': 'Southern Asia'
        },
        {
          'value': '035',
          'label': 'South-Eastern Asia'
        },
        {
          'value': '145',
          'label': 'Western Asia'
        },
        {
          'value': '053',
          'label': 'Australia and New Zealand'
        },
        {
          'value': '054',
          'label': 'Melanesia'
        },
        {
          'value': '057',
          'label': 'Micronesia'
        },
        {
          'value': '061',
          'label': 'Polynesia'
        }
      ],
      chartOptions: {
        colorAxis: {minValue: 0,  colors: ['#267114', '#FFB401', '#B70B0D']},
        datalessRegionColor: '#267114',
        region: 'world'
      }
    }
  },
  watch: {
    region() {
      this.chartOptions.region = this.region;
    }
  },
  created() {
    this.fetchCountryAttack();
  },
  methods: {
    fetchCountryAttack() {
      axios.get('attack/country/').then(response => {
        this.chartData = [];
        this.chartData.push([this.$t('Country'), this.$t('Attack')]);
        response.data.forEach(item => {
          this.chartData.push([item.country, item.total]);
        });
      }).catch((error) => {
        console.log(error);
      })
    }
  }
}
</script>
